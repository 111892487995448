<template>
  <div id="ates-policy" class="mb-5">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <p class="title is-4 has-text-right">
            {{ classGroupName }}
          </p>
        </div>
        <div
          class="column is-8 is-offset-2 card mb-3"
          v-for="policy in Object.entries(policies)"
          :key="policy[0]"
        >
          <div class="field">
            <b-switch v-model="policies[policy[0]]" type="is-success">{{
              policy[0].toUpperCase()
            }}</b-switch>
          </div>
        </div>
        <div class="column is-8 is-offset-2 pr-0">
          <div class="buttons is-right">
            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized"
              @click="update"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'Ates Policy',
      schoolId: null,
      isSwitched: false,
      classGroupName: null,
      atesPolicy: {},
      policies: {},
    }
  },
  watch: {
    atesPolicy(data) {
      const { __typename, classGroup, ...rest } = data
      this.classGroupName = classGroup.name
      this.policies = rest
    },
  },
  methods: {
    update() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateAtesPolicyQuery($id: Int!, $data: JSON!) {
              updateAtesPolicy(input: { id: $id, data: $data }) {
                atesPolicy {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.$route.params.id),
            data: this.policies,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAtesPolicy.errors,
            'Successfully updated.'
          ).then(() => {
            this.$router.push(`/school/${this.schoolId}/ates_policies`)
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Ates Policies',
        route: `/school/${this.schoolId}/ates_policies`,
      },
    ])

    this.$apollo.addSmartQuery('atesPolicy', {
      query: gql`
        query AtesPolicyQuery($id: ID!) {
          atesPolicy(id: $id) {
            classGroup {
              id
              name
            }
            fa
            sa
            ft
            st
            aff
            exams
          }
        }
      `,
      variables: {
        id: this.$route.params.id,
      },
    })

    this.$apollo.queries.atesPolicy.refetch()
  },
}
</script>
